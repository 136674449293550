
$(document).foundation();

$(document).ready(function() {

    /*
    *   General pages
    */

    // google analytics
    $('[data-ga-label]').each(function(){
        $(this).on('click', function() {
            var $trackingName = $(this).data('ga-label'),
            $tagName = $(this).prop("tagName"),
            $action = 'click';
            if($tagName=='input'){
                $action = 'submit';
            }
            ga('send', 'event', 'button', $action, $trackingName);
        });
    });


    /*
    *   Home page
    */

    var pathname = window.location.pathname;
    if($('.product-search').length > 0){
        var pageURL = "https://" + window.location.host;
        // var pageURL = "https://" + window.location.host + '/thule.co.nz';

        $('.product-search').autocomplete({
            serviceUrl : pageURL + '/ProductSearch',
            minChars : 3,
            onSearchStart : function(input) {
                dataLayer.push({
                    'event': 'searchKeyword',
                    'eventLabel': input.query
                });
            },
            onSelect : function(suggestion) {
                dataLayer.push({
                    'event': 'searchProduct',
                    'eventLabel': suggestion.value
                });
                window.location = suggestion.data.url;
            },
            onSearchComplete : function(query, suggestions) {
                if(!suggestions.length){
                    console.log('no suggestion');
                }
            },
            groupBy : 'category'
        });
    }

    /*
    *   Fit My Car functionality
    */

    var fitMyCarData;

    function FitMyCarModel(currentURL) {
        var self = this;
        self.selectedMake = '';
        self.selectedModel = '';
        self.selectedYear = '';
        self.selectedVariant = '';

        self.getFitMyCarProducts = function () {
            self.selectedMake  = self.selectedMake;
            self.selectedModel = self.selectedModel;
            self.selectedYear = self.selectedYear;
            self.selectedVariant = encodeURIComponent(self.selectedVariant);
            var url = currentURL + "/FitMyCarProducts?make=" + self.selectedMake + "&model=" + self.selectedModel + "&year=" + self.selectedYear + "&variant=" + self.selectedVariant;
            var products = '';
            $.ajax({
                url : url,
                type : 'GET',
                dataType: "json"
            }).done(function( results ) {
                // console.log(results);

                // $('.make-container').removeClass('not-active');

                if(results.products.length == 1 && results.products[0].no_fit) {
                    products += '';
                    $('.no-products').addClass('show');
                } else {
                    $('.filter-bar').addClass('is-active');
                    $('.no-products').removeClass('show');
                    // products += '<div class="small-12 columns"><h3>' + results.products.length + ' Products</h3></div>';

                    $.each(results.products, function(key, value) {
                        if(value.no_fit == 'y') {
                            products += '';
                            $('.no-products').addClass('show');
                        } else {
                            products += '<div class="grid-product ' + value.filter + '"><div class="inner">';                            

                            // Stock level 
                            products += '<div class="tag tag--' + value.stock_level.replace(/\s+/g, '-').toLowerCase() + ' absolute top-0 right-0 m-3">' + value.stock_level + "</div>";

                            // Photo
                            if(value.bar_photo != null) {
                                products += '<div class="img-container"><img src="' + value.bar_photo + '" alt="' + value.bar_type + '" /></div>';
                            } else {
                                products += '<div class="img-container"><img src="themes/base/images/fitkit.jpg" alt="' + value.bar_type + '" /></div>';
                            }

                            // Kit Title
                            products += '<div class="pack-details"><div class="pack-details__text"><h4 class="h5">' + value.bar_type + '</h4>';
                            
                            // Bar guide
                            if(value.bar_details !== null) {
                                products += '<a href="' + value.bar_details.Link + '" class="bar-details">Bar Guide</a></div>';
                            }
                            else {
                                products += '</div>';
                            }

                            // Kit Price
                            if(value.price != '' && value.price != '0.00') {
                                products += '<div class="pack-details__price">$' + value.price + ' <span>(RRP)</span></div>';
                            }

                            // Kit Items
                            products += '<div class="sub-title">Pack Includes:</div>';

                            // Foot pack
                            if(value.foot != '') {
                                if(value.foot_details !== null) {
                                    products += '<a href="' + value.foot_details.Link + '" class="details" target="_blank">';
                                } else {
                                    products += '<div class="details">';
                                }

                                products += '<span class="title">Foot Pack</span>';
                                products += '<span class="code">' + value.foot + '</span>';
                                if(value.foot_details !== null) {
                                    products += '<span class="price">$' + value.foot_details.Price + '</span>';
                                }
                                if(value.foot_details !== null) {
                                    products += '</a>';
                                } else {
                                    products += '</div>';
                                }
                            }

                            //Bar pack
                            if(value.bar_number != '') {
                                if(value.bar_details !== null) {
                                    products += '<a href="' + value.bar_details.Link + '" class="details" target="_blank">';
                                } else {
                                    products += '<div class="details">';
                                }

                                products += '<span class="title">Bar Pack</span>';
                                products += '<span class="code">' + value.bar_number + '</span>';
                                if(value.bar_details !== null) {
                                    if(value.bar_details.Price != '' && value.bar_details.Price != '0.00') {
                                        products += '<span class="price">$' + value.bar_details.Price + '</span>';
                                    }
                                }
                                if(value.bar_details !== null) {
                                    products += '</a>';
                                } else {
                                    products += '</div>';
                                }
                            }

                            // Fit Kit
                            if(value.kit != '') {
                                if(value.kit_pdf !== null) {
                                    products += '<a href="' + value.kit_pdf + '" class="details" target="_blank">';
                                } else {
                                    products += '<div class="details">';
                                }

                                products += '<span class="title">Fit Kit</span>';
                                products += '<span class="code">' + value.kit + '</span>';
                                if(value.kit_pdf !== null) {
                                    products += '<span class="price">View fit guide</span>';
                                }
                                if(value.kit_pdf !== null) {
                                    products += '</a>';
                                } else {
                                    products += '</div>';
                                }
                            }

                            // Short Roof Line Adapter
                            if(value.short_roof != '') {
                                products += '<div class="details">';
                                products += '<span class="title">Short Roof<br />Line Adapter</span>';
                                products += '<span class="code">' + value.short_roof + '</span>';
                                products += '</div>';
                            }

                            // Lock pack
                            if(value.Lock != '') {
                                if(value.lock_details !== null) {
                                    products += '<a href="' + value.lock_details.Link + '" class="details" target="_blank">';
                                } else {
                                    products += '<div class="details">';
                                }

                                products += '<span class="title">Lock</span>';
                                products += '<span class="code">' + value.Lock + '</span>';
                                if(value.lock_details !== null) {
                                    products += '<span class="price">$' + value.lock_details.Price + '</span>';
                                }
                                if(value.lock_details !== null) {
                                    products += '</a>';
                                } else {
                                    products += '</div>';
                                }
                            }

                            // Load
                            if(value.load != '') {
                                products += '<div class="details">';
                                products += '<span class="title">Max Load</span>';
                                products += '<span class="code">' + value.load + 'kg</span>';
                                products += '</div>';
                            }

                            // if(value.kit != '') {
                            //     products += 'Kit: ' + value.kit + '<br />';
                            // }

                            products += '</div></div></div>';
                        }
                    });
                }

                var $items = $(products);
                // remove the current items
                $grid.isotope('remove', $grid.isotope('getItemElements')).isotope('layout');
                $grid.isotope('insert', $items);
            });
        }

        self.selectCarVariant = function(variant){
            self.selectedVariant = variant;
            clearIsotopeProducts();
            // $('.make-container').addClass('not-active');
            $('.variant-result').html(variant);
            $('.car-accordion').foundation('up', $('#variant-panel'));
            self.getFitMyCarProducts();
        }

        self.selectCarYear = function(year){
            self.selectedYear = year;
            var url = currentURL + "/FitMyCarVariants?make=" + self.selectedMake + "&model=" + self.selectedModel + "&year=" + self.selectedYear,
                options = "";

            clearIsotopeProducts();
            // $('.make-container').addClass('not-active');
            $('.variant-result').html('');
            $('#car-variants').html('');
            $(".year-result").html(year);

            $.ajax({
                url : url,
                type : 'GET',
                dataType: "json"
            }).done(function( results ) {
                // console.log(results);

                $(".variant-accordion").removeClass('disabled');

                $.each(results.variants, function(key, value) {
                    options += '<span data-variant="' + value.data + '">' + value.value + '</span>';
                });
                $("#car-variants").html( options );
                if(results.variants.length === 1) {
                    $('#car-variants > span:first-child').addClass('selected');
                    self.selectCarVariant(results.variants[0].value);
                    $('.car-accordion').foundation('up', $('#year-panel'));
                } else {
                    $('.car-accordion').foundation('down', $('#variant-panel'));
                }
            });
        }

        self.selectCarModel = function(model) {
            self.selectedModel = model;
            var url = currentURL + "/FitMyCarYears?make=" + self.selectedMake + "&model=" + self.selectedModel,
                options = "";

            clearIsotopeProducts();
            // $('.make-container').addClass('not-active');
            $('.year-result').html('');
            $('.variant-result').html('');
            $('#car-years').html('');
            $('#car-variants').html('');
            $(".model-result").html(model);
            $(".variant-accordion").addClass('disabled');

            $.ajax({
                url : url,
                type : 'GET',
                dataType: "json"
            }).done(function(results) {
                // console.log(results);

                $(".year-accordion").removeClass('disabled');

                $.each(results.years, function(key, value) {
                    options += '<span data-year="' + value.data + '">' + value.value + '</span>';
                });
                options += '<div class="note">If your vehicle is in a changeover year please confirm which age range is applicable.</div>';
                $("#car-years").html( options );
                if(results.years.length === 1){
                    $('#car-years > span:first-child').addClass("selected");
                    self.selectCarYear(results.years[0].data);
                    $('.car-accordion').foundation('up', $('#model-panel'));
                } else {
                  $('.car-accordion').foundation('down', $('#year-panel'));
                }
            });
        }

        self.selectCarMake = function (make) {
            self.selectedMake = make;
            var url = currentURL + "/FitMyCarModels?make=" + self.selectedMake,
                options = "";

            $.ajax({
                url : url,
                type : 'GET',
                dataType: "json"
            }).done(function(results) {
                // console.log(results);

                $.each(results.models, function(key, value) {
                    options += '<span data-model="' + value.data + '">' + value.value + '</span>';
                });
                // $('.make-container').addClass('not-active');
                $('.model-accordion').removeClass('disabled');
                $("#car-models").html( options );
                if(results.models.length === 1){
                    $('#car-models > span:first-child').addClass('selected');
                    self.selectCarModel(results.models[0].value);
                } else {
                  $('.car-accordion').foundation('down', $('#model-panel'));
                }
            });
        }
    }

    // Clear isotope products and reset to all
    function clearIsotopeProducts() {
        $grid.isotope( 'remove', $("#fit-my-car-results .grid-product")).isotope('layout').isotope({filter: '*'});
        $('#fit-my-car-results').html('');
        $('.button-group button').removeClass('is-checked');
        $('.button-group button:first-child').addClass('is-checked');
        $('.filter-bar').removeClass('is-active');
    }

    // Fit My Car Make search
    if($('#fit-my-car-makes').length > 0){
        fitMyCarData = new FitMyCarModel("https://" + window.location.host + window.location.pathname);
        $('.car-accordion .accordion-item').addClass('disabled');
        // debugger;

        // Get car models based on the make
        // Get car years/months based on the make and model
        $("#car-models").on("click", "span", function() {
            var modelSelected = $(this).attr('data-model');
            fitMyCarData.selectCarModel(modelSelected);
            $('#car-models > span').removeClass('selected');
            $(this).addClass('selected');
        });

        // Get car variants based on the make, model and year/month
        // function getCarVariants(currentURL) {
        $("#car-years").on("click", "span", function() {
            var yearSelected = $(this).attr('data-year');
            fitMyCarData.selectCarYear(yearSelected);
            $('#car-years > span').removeClass('selected');
            $(this).addClass('selected');
        });

        $("#car-variants").on("click", "span", function() {
            var variant = $(this).attr('data-variant');
            fitMyCarData.selectCarVariant(variant);
            $('#car-variants > span').removeClass('selected');
            $(this).addClass('selected');
        });

        var $grid = $('#fit-my-car-results').isotope({
          itemSelector: '.grid-product',
          layoutMode: 'fitRows'
        });

        $('.filter-button-group').on( 'click', 'button', function() {
          var filterValue = $( this ).attr('data-filter');
          // use filterFn if matches value
          // filterValue = filterFns[ filterValue ] || filterValue;
          $grid.isotope({ filter: filterValue });
        });

        $('.button-group').each( function( i, buttonGroup ) {
            var $buttonGroup = $( buttonGroup );
            $buttonGroup.on( 'click', 'button', function() {
                $buttonGroup.find('.is-checked').removeClass('is-checked');
                $( this ).addClass('is-checked');
            });
        });

        // Autocomplete for the make selector
        $('#fit-my-car-makes').autocomplete({
            lookup : makes,
            minChars : 2,
            autoSelectFirst : true,
            onSearchStart : function(query){
                clearIsotopeProducts();
                // $('.make-container').removeClass('not-active');
                $('.search-result').html('');
                $('#car-models').html('');
                $('#car-years').html('');
                $('#car-variants').html('');
                $('.car-accordion').foundation('up', $('#model-panel'));
                $('.car-accordion').foundation('up', $('#year-panel'));
                $('.car-accordion').foundation('up', $('#variant-panel'));
                $(".model-accordion").addClass('disabled');
                $(".year-accordion").addClass('disabled');
                $(".variant-accordion").addClass('disabled');
            },
            onSelect : function(suggestion) {
                fitMyCarData.selectCarMake(suggestion.data);
            }
        });
    }

    /*
    *   Fit My Car Bike Rack functionality
    */

    var fitMyCarBikeData;

    function FitMyCarBikeModel(currentURL) {
        var self = this;
        self.selectedMake = '';
        self.selectedModel = '';
        self.selectedYear = '';
        self.selectedVariant = '';

        self.getFitMyCarProducts = function () {
            self.selectedMake  = self.selectedMake;
            self.selectedModel = self.selectedModel;
            self.selectedYear = self.selectedYear;
            self.selectedVariant = encodeURIComponent(self.selectedVariant);
            var url = currentURL + "/FitMyCarBikeProducts?variant=" + self.selectedVariant;
            var products = '';
            $.ajax({
                url : url,
                type : 'GET',
                dataType: "json"
            }).done(function( results ) {
                // $('.bike-rack-make-container').removeClass('not-active');

                if(results.products.length < 1) {
                    products += '';
                    $('.no-products').addClass('show');
                } else {
                    $('.no-products').removeClass('show');
                    $.each(results.products, function(key, value) {
                        console.log(value);
                        products += '<div class="grid-product"><div class="inner">';
                        if(value.ImageLink != null) {
                            products += '<div class="img-container"><img src="' + value.ImageLink + '" alt="' + value.Title + '" /></div>';
                        } else {
                            products += '<div class="img-container"><img src="themes/base/images/fitkit.jpg" alt="' + value.bar_type + '" /></div>';
                        }

                        products += '<div class="pack-details"><div class="pack-details__text"><h4 class="h5">' + value.Title + '</h4></div>';

                        if(value.Price != '' && value.Price != '0.00') {
                            products += '<div class="pack-details__price">$' + value.Price + ' <span>(RRP)</span></div>';
                        }

                        if(value.FitKitLink !== null && value.FitKitTitle !== null) {
                            products += '<a href="' + value.FitKitLink + '" class="details" target="_blank">Requires ' + value.FitKitTitle + '</a>';
                        }

                        if(value.Link !== null) {
                            products += '<a href="' + value.Link + '" class="details main" target="_blank">View more details</a>';
                        }

                        products += '</div></div></div>';
                    });
                }

                var $items = $(products);
                // remove the current items
                $bikeRacksGrid.isotope('remove', $bikeRacksGrid.isotope('getItemElements')).isotope('layout');
                $bikeRacksGrid.isotope('insert', $items);
            });
        }

        self.selectCarVariant = function(variant, variantID){
            self.selectedVariant = variantID;
            clearBikeRackIsotopeProducts();
            // $('.bike-rack-make-container').addClass('not-active');
            $('.bike-rack-variant-result').html(variant);
            $('.bike-rack-accordion').foundation('up', $('#bike-rack-variant-panel'));
            self.getFitMyCarProducts();
        }

        self.selectCarYear = function(year,yearID){
            self.selectedYear = yearID;
            var url = currentURL + "/FitMyCarBikeVariants?year=" + self.selectedYear,
                options = "";

            clearBikeRackIsotopeProducts();
            // $('.bike-rack-make-container').addClass('not-active');
            $('.bike-rack-variant-result').html('');
            $('#bike-rack-car-variants').html('');
            $(".bike-rack-year-result").html(year);

            $.ajax({
                url : url,
                type : 'GET',
                dataType: "json"
            }).done(function( results ) {

                $(".bike-rack-variant-accordion").removeClass('disabled');

                $.each(results.variants, function(key, value) {
                    options += '<span data-variant="' + value.data + '" data-title="' + value.value + '">' + value.value + '</span>';
                });
                $("#bike-rack-car-variants").html( options );
                if(results.variants.length === 1) {
                    $('#bike-rack-car-variants > span:first-child').addClass('selected');
                    self.selectCarVariant(results.variants[0].value, results.variants[0].data);
                    $('.bike-rack-accordion').foundation('up', $('#bike-rack-year-panel'));
                } else {
                    $('.bike-rack-accordion').foundation('down', $('#bike-rack-variant-panel'));
                }
            });
        }

        self.selectCarModel = function(model,modelID) {
            self.selectedModel = modelID;
            var url = currentURL + "/FitMyCarBikeYears?model=" + self.selectedModel,
                options = "";

            clearBikeRackIsotopeProducts();
            // $('.bike-rack-make-container').addClass('not-active');
            $('.bike-rack-year-result').html('');
            $('.bike-rack-variant-result').html('');
            $('#bike-rack-car-years').html('');
            $('#bike-rack-car-variants').html('');
            $(".bike-rack-model-result").html(model);
            $(".bike-rack-variant-accordion").addClass('disabled');

            $.ajax({
                url : url,
                type : 'GET',
                dataType: "json"
            }).done(function(results) {
                $(".bike-rack-year-accordion").removeClass('disabled');

                $.each(results.years, function(key, value) {
                    options += '<span data-year="' + value.data + '" data-title="' + value.value + '">' + value.value + '</span>';
                });
                options += '<div class="note">If your vehicle is in a changeover year please confirm which age range is applicable.</div>';
                $("#bike-rack-car-years").html( options );
                if(results.years.length === 1){
                    $('#bike-rack-car-years > span:first-child').addClass("selected");
                    self.selectCarYear(results.years[0].value, results.years[0].data);
                    $('.bike-rack-accordion').foundation('up', $('#bike-rack-model-panel'));
                } else {
                  $('.bike-rack-accordion').foundation('down', $('#bike-rack-year-panel'));
                }
            });
        }

        self.selectCarMake = function (make) {
            self.selectedMake = make;
            var url = currentURL + "/FitMyCarBikeModels?make=" + self.selectedMake,
                options = "";

            $.ajax({
                url : url,
                type : 'GET',
                dataType: "json"
            }).done(function(results) {
                $.each(results.models, function(key, value) {
                    options += '<span data-model="' + value.data + '" data-title="' + value.value + '">' + value.value + '</span>';
                });
                // $('.bike-rack-make-container').addClass('not-active');
                $('.bike-rack-model-accordion').removeClass('disabled');
                $("#bike-rack-car-models").html( options );
                if(results.models.length === 1){
                    $('#bike-rack-car-models > span:first-child').addClass('selected');
                    self.selectCarModel(results.models[0].value, results.models[0].data);
                } else {
                  $('.bike-rack-accordion').foundation('down', $('#bike-rack-model-panel'));
                }
            });
        }
    }

    // Fit My Car Make search
    if($('#fit-my-car-bike-makes').length > 0) {

        fitMyCarBikeData = new FitMyCarBikeModel("https://" + window.location.host + window.location.pathname);
        $('.bike-rack-accordion .accordion-item').addClass('disabled');

        // Get car models based on the make
        // Get car years/months based on the make and model
        $("#bike-rack-car-models").on("click", "span", function() {
            var modelSelected = $(this).attr('data-title'),
                modelID = $(this).attr('data-model')
            fitMyCarBikeData.selectCarModel(modelSelected, modelID);
            $('#bike-rack-car-models > span').removeClass('selected');
            $(this).addClass('selected');
        });

        // Get car variants based on the make, model and year/month
        //function getCarVariants(currentURL) {
        $("#bike-rack-car-years").on("click", "span", function() {
            var yearSelected = $(this).attr('data-title'),
                yearID = $(this).attr('data-year');
            fitMyCarBikeData.selectCarYear(yearSelected, yearID);
            $('#bike-rack-car-years > span').removeClass('selected');
            $(this).addClass('selected');
        });

        $("#bike-rack-car-variants").on("click", "span", function() {
            var variant = $(this).attr('data-title'),
                variantID = $(this).attr('data-variant');
            fitMyCarBikeData.selectCarVariant(variant, variantID);
            $('#bike-rack-car-variants > span').removeClass('selected');
            $(this).addClass('selected');
        });

        var $bikeRacksGrid = $('#fit-my-car-bike-results').isotope({
            itemSelector: '.grid-product',
            layoutMode: 'fitRows'
        });


        // Autocomplete for the make selector
        $('#fit-my-car-bike-makes').autocomplete({
            minChars : 2,
            autoSelectFirst : true,
            serviceUrl: 'http://' + window.location.host + window.location.pathname + '/FitMyCarBikeMakes',
            onSearchStart : function(query){
                clearBikeRackIsotopeProducts();
                // $('.bike-rack-make-container').removeClass('not-active');
                $('.bike-rack-search-result').html('');
                $('#bike-rack-car-models').html('');
                $('#bike-rack-car-years').html('');
                $('#bike-rack-car-variants').html('');
                $('.bike-rack-accordion').foundation('up', $('#bike-rack-model-panel'));
                $('.bike-rack-accordion').foundation('up', $('#bike-rack-year-panel'));
                $('.bike-rack-accordion').foundation('up', $('#bike-rack-variant-panel'));
                $(".bike-rack-model-accordion").addClass('disabled');
                $(".bike-rack-year-accordion").addClass('disabled');
                $(".bike-rack-variant-accordion").addClass('disabled');
            },
            onSelect : function(suggestion) {
                fitMyCarBikeData.selectCarMake(suggestion.data);
            }
        });

        // Clear isotope products and reset to all
        function clearBikeRackIsotopeProducts() {
            $bikeRacksGrid.isotope( 'remove', $("#fit-my-car-bike-results .grid-product")).isotope('layout').isotope({filter: '*'});
            $('#fit-my-car-bike-results').html('');
            $('.button-group button').removeClass('is-checked');
            $('.button-group button:first-child').addClass('is-checked');
            // $('.filter-bar').hide();
        }
    }

    // generic to both fit my car and bike racks
    if($('#fit-my-car-makes').length > 0 || $('#fit-my-car-bike-makes').length > 0) {
        $(".accordion a").each(function(){
            var data = $.hasData(this) && $._data(this);
            // Be careful not to assign other click events...
            var foundationClickHandler = data.events.click[0].handler;
            var foundationKeyDownHandler = data.events.keydown[0].handler;

            function allowEvents(elem){
                return !$(elem).parent().hasClass("disabled");
            }

            // Remove the foundation click handler and add our own.
            $(this).off("click.zf.accordion").on("click", function(e){
                e.preventDefault();
                if(allowEvents(this)) {
                    foundationClickHandler(e);
                }
            });
            $(this).off("keydown.zf.accordion").on("keydown", function(e){
                e.preventDefault();
                if(allowEvents(this)) {
                    foundationKeyDownHandler(e);
                }
            });
        });
    }

    //isotope for bike rack products search/filtering
    // if($('.BikeRackSearchPage').length > 0 || $('#bike-rack-results').length > 0){
    //   var $grid = $('#bike-rack-results').isotope({
    //     itemSelector: '.product-listing',
    //     layoutMode: 'fitRows'
    //   });
    //   $('.category-filters #all-results').addClass('is-checked');
    //   $('.category-filters').on( 'click', 'button', function() {
    //     var filterValue = $( this ).attr('data-filter');
    //     $grid.isotope({ filter: filterValue });
    //   });
    //   $('.category-filters .button').each( function(){
    //     var categoryFilterId = $(this).attr('id');
    //     var searchClass = $('.product-listing' + '.' + categoryFilterId);
    //     if(!$('#bike-rack-results').find(searchClass).length && categoryFilterId != 'all-results'){
    //         $('#' + categoryFilterId).remove();
    //     }
    //   });
    //   $('.category-filters').each( function( i, buttonGroup ) {
    //     var $buttonGroup = $( buttonGroup );
    //     $buttonGroup.on( 'click', 'button', function() {
    //       $buttonGroup.find('.is-checked').removeClass('is-checked');
    //       $( this ).addClass('is-checked');
    //     });
    //   });
    // }

    
    ////////////////////////////////////////////////////////////////////////////
    // Fit My Rim functionality

    var fitMyRimData;

    function FitMyRims(currentURL){
        var self = this;
        self.selectedWidth = '';
        self.selectedHeight = '';
        self.selectedRim = '';

        self.getFitMyRimProducts = function() {
            var url = currentURL + "/FitMyRimProducts?width=" + self.selectedWidth + "&height=" + self.selectedHeight + "&rim=" + self.selectedRim;
            var products = '';

            $.ajax({
                url : url,
                type : 'GET',
                dataType : 'json'
            }).done(function(results) {
                // console.log(results);

                if(results.products.length == 1) {
                    products += '';
                    $('.no-products').addClass('show');
                } else {
                    var count = 0;
                    $('.no-products').removeClass('show');
                    $.each(results.products, function(key, value) {
                        if(value != null) {
                            products += '<div class="grid-product"><div class="inner">';
                            // image
                            if(value.imageLink != null) {
                                products += '<div class="img-container"><img src="' + value.imageLink + '" alt="' + value.title + ' ' + value.code + '"></div>';
                            } else {
                                products += '<div class="img-container"><img src="themes/konig/images/snow-chains.jpg" alt="' + value.title + ' ' + value.code + '"></div>';
                            }
                            products += '<div class="pack-details">';
                            products += '<div class="pack-details__text"><h4 class="h5">' + value.title + ' ' + value.code + '</h4></div>';
                            if(value.price != null) {
                                products += '<div class="pack-details__price">$' + value.price + '<span>(RRP)</span></div>';
                            }
                            if(value.productLink != null) {
                                products += '<a href="' + value.productLink + '" class="details" target="_blank">View more details</a>';
                            }
                            products += '</div></div></div>';

                            count++;
                        }
                    });
                    if(count == 0) {
                        products += '';
                        $('.no-products').addClass('show');
                    } else {
                        // disclaimer
                        if(results.disclaimer !== null) {
                            $('#rim-tab-panel .disclaimer').html('<a href="' + results.disclaimer + '" target="_blank">* View Fitting Information</a>');
                        }
                    }
                }

                var $rimItems = $(products);
                // remove the current items
                $rimGrid.isotope('remove', $rimGrid.isotope('getItemElements')).isotope('layout');
                $rimGrid.isotope('insert', $rimItems);
            });
        }

        self.selectRimRims = function(rim){
            self.selectedRim = rim;
            clearRimIsotopeProducts();
            $('.width-container').addClass('not-active');
            $('.rim-result').html(rim);
            $('.accordion').foundation('up', $('#rim-panel'));
            self.getFitMyRimProducts();
        }

        self.selectRimHeight = function(height) {
            self.selectedHeight = height;
            var url = currentURL + "/FitMyRimRims?width=" + self.selectedWidth + '&height=' + self.selectedHeight,
                options = "";

            clearRimIsotopeProducts();
            $('.width-container').addClass('not-active');
            $('.rim-result').html('');
            $('#rim-rims').html('');
            $(".height-result").html(height);

            $.ajax({
                url : url,
                type : 'GET',
                dataType: "json"
            }).done(function(results) {
                // console.log(results);

                $('.rim-accordion').removeClass('disabled');

                $.each(results.rims, function(key, value) {
                    // options += '<span data-rim="' + value.data + '">' + value.value + '</span>';
                    $.each(value, function(key, value) {
                        options += '<span data-rim="' + value.data + '">' + value.value + '</span>';
                    });
                });

                $("#rim-rims").html( options );

                if(results.totalRims === 1) {
                    $('#rim-rims > span:first-child').addClass('selected');
                    self.selectRimRims(results.rims[0][0].value);
                    $('.rim-accordion-container').foundation('up', $('#height-panel'));
                } else {
                    $('.rim-accordion-container').foundation('down', $('#rim-panel'));
                }
            });
        }

        self.selectRimWidth = function(width) {
            self.selectedWidth = width;
            var url = currentURL + "/FitMyRimHeights?width=" + self.selectedWidth,
                options = "";

            clearRimIsotopeProducts();
            $('.height-result').html('');
            $('.rim-result').html('');
            $('#rim-heights').html('');
            $('#rim-rims').html('');
            $(".width-result").html(width);
            $(".rim-accordion").addClass('disabled');

            $.ajax({
                url : url,
                type : 'GET',
                dataType: "json"
            }).done(function(results) {
                // console.log(results);

                $('.height-accordion').removeClass('disabled');

                $.each(results.heights, function(key, value) {
                    $.each(value, function(key, value) {
                        options += '<span data-height="' + value.data + '">' + value.value + '</span>';
                    });
                });

                $("#rim-heights").html( options );

                if(results.totalHeights === 1){
                    $('#rim-heights > span:first-child').addClass('selected');
                    self.selectRimHeight(results.heights[0][0].value);
                    $('.rim-accordion-container').foundation('up', $('#width-panel'));
                } else {
                  $('.rim-accordion-container').foundation('down', $('#height-panel'));
                }
            });
        }

    }

    // Clear isotope products and reset to all
    function clearRimIsotopeProducts() {
        $rimGrid.isotope( 'remove', $("#fit-my-rim-results .grid-product")).isotope('layout').isotope({filter: '*'});
        $('#fit-my-rim-results').html('');
        $('.button-group button').removeClass('is-checked');
        $('.button-group button:first-child').addClass('is-checked');
        $('.filter-bar').hide();
        $('#rim-tab-panel .disclaimer').html('');
    }



    ////////////////////////////////////////////////////////////////////////////
    // Fit My Wheel functionality

    var fitMyWheelData;

    function FitMyWheelModel(currentURL){
        var self = this;
        self.selectedMake = '';
        self.selectedModel = '';
        self.selectedTire = '';

        self.getFitMyWheelProducts = function() {
            var url = currentURL + "/FitMyWheelProducts?make=" + self.selectedMake + "&model=" + self.selectedModel + "&tire=" + self.selectedTire;
            var products = '';

            $.ajax({
                url : url,
                type : 'GET',
                dataType : 'json'
            }).done(function(results) {
                // console.log(results);

                $('.make-container').removeClass('not-active');

                if(results.products.length == 1) {
                    products += '';
                    $('.no-products').addClass('show');
                } else {
                    $('.no-products').removeClass('show');
                    $.each(results.products, function(key, value) {
                        if(value != null) {
                            products += '<div class="grid-product"><div class="inner">';
                            // image
                            if(value.imageLink != null) {
                                products += '<div class="img-container"><img src="' + value.imageLink + '" alt="' + value.title + ' ' + value.code + '"></div>';
                            } else {
                                products += '<div class="img-container"><img src="themes/konig/images/snow-chains.jpg" alt="' + value.title + ' ' + value.code + '"></div>';
                            }
                            products += '<div class="pack-details">';
                            products += '<div class="pack-details__text"><h4 class="h5">' + value.title + ' ' + value.code + '</h4></div>';
                            if(value.price != null) {
                                products += '<div class="pack-details__price">$' + value.price + '<span>(RRP)</span></div>';
                            }
                            if(value.productLink != null) {
                                products += '<a href="' + value.productLink + '" class="details" target="_blank">View more details</a>';
                            }
                            products += '</div></div></div>';
                        }
                    });
                    // disclaimer
                    if(results.disclaimer !== null) {
                        $('#wheel-tab-panel .disclaimer').html('<a href="' + results.disclaimer + '" target="_blank">* View Fitting Information</a>');
                    }
                }

                var $items = $(products);
                // remove the current items
                $grid.isotope('remove', $grid.isotope('getItemElements')).isotope('layout');
                $grid.isotope('insert', $items);
            });
        }

        self.selectCarTire = function(tire) {
            self.selectedTire = tire;
            $('.make-container').addClass('not-active');
            $('.tire-result').html(tire);
            $('.accordion').foundation('up', $('#tire-panel'));
            self.getFitMyWheelProducts();
        }

        self.selectCarModel = function(model) {
            self.selectedModel = model;
            var url = currentURL + "/FitMyWheelTires?make=" + self.selectedMake + "&model=" + self.selectedModel,
                options = "";

            $('.make-container').addClass('not-active');
            $('.tire-result').html('');
            $('#car-tires').html('');
            $(".model-result").html(model);

            $.ajax({
                url : url,
                type : 'GET',
                dataType : 'json'
            }).done(function(results) {
                // console.log(results);

                $(".tire-accordion").removeClass('disabled');

                $.each(results.tires, function(key, value) {
                    $.each(value, function(key, value) {
                        options += '<span data-tire="' + value.data + '">' + value.value + '</span>';
                    });
                });
                $("#car-tires").html( options );
                if(results.totalTires === 1){
                    $('#car-tires > span:first-child').addClass("selected");
                    self.selectCarTire(results.tires[0][0].data);
                    $('.accordion').foundation('up', $('#model-panel'));
                } else {
                  $('.accordion').foundation('down', $('#tire-panel'));
                }
            });
        }

        self.selectCarMake = function(make) {
            self.selectedMake = make;
            var url = currentURL + "/FitMyWheelModels?make=" + self.selectedMake,
                options = "";

            $.ajax({
                url : url,
                type : 'GET',
                dataType: "json"
            }).done(function(results) {
                // console.log(results);

                $.each(results.models, function(key, value) {
                    $.each(value, function(key, value) {
                        options += '<span data-model="' + value.data + '">' + value.value + '</span>';
                    });
                });
                $('.make-container').addClass('not-active');
                $('.model-accordion').removeClass('disabled');
                $("#car-models").html( options );
                if(results.totalModels === 1){
                    $('#car-models > span:first-child').addClass('selected');
                    self.selectCarModel(results.models[0][0].value);
                } else {
                  $('.accordion').foundation('down', $('#model-panel'));
                }
            });
        }

    }

    // Clear isotope products and reset to all

    function clearIsotopeProducts() {
        $grid.isotope( 'remove', $("#fit-my-wheel-results .grid-product")).isotope('layout').isotope({filter: '*'});
        $('#fit-my-wheel-results').html('');
        $('.button-group button').removeClass('is-checked');
        $('.button-group button:first-child').addClass('is-checked');
        $('.filter-bar').hide();
        $('#wheel-tab-panel .disclaimer').html('');
    }

    // Generic to both Fit My Wheel and Fit My Rim

    if($('#fit-my-wheel-makes').length > 0 || $('#rim-panel').length > 0){

        var fullURL = "https://" + window.location.host + window.location.pathname;

        $(".accordion a").each(function(){
            var data = $.hasData(this) && $._data(this);
            // Be careful not to assign other click events...
            var foundationClickHandler = data.events.click[0].handler;
            var foundationKeyDownHandler = data.events.keydown[0].handler;

            function allowEvents(elem){
                return !$(elem).parent().hasClass("disabled");
            }

            // Remove the foundation click handler and add our own.
            $(this).off("click.zf.accordion").on("click", function(e){
                e.preventDefault();
                if(allowEvents(this)) {
                    foundationClickHandler(e);
                }
            });
            $(this).off("keydown.zf.accordion").on("keydown", function(e){
                e.preventDefault();
                if(allowEvents(this)) {
                    foundationKeyDownHandler(e);
                }
            });
        });

        if($('#fit-my-wheel-makes').length > 0) {
            fitMyWheelData = new FitMyWheelModel(fullURL);
            $('#wheel-tab-panel .accordion-item').addClass('disabled');

            $("#car-models").on("click", "span", function() {
                clearIsotopeProducts();
                var modelSelected = $(this).attr('data-model');
                fitMyWheelData.selectCarModel(modelSelected);
                $('#car-models span').removeClass('selected');
                $(this).addClass('selected');
            });

            $("#car-tires").on("click", "span", function() {
                clearIsotopeProducts();
                var tireSelected = $(this).attr('data-tire');
                fitMyWheelData.selectCarTire(tireSelected);
                $('#car-tires span').removeClass('selected');
                $(this).addClass('selected');
            });

            var $grid = $('#fit-my-wheel-results').isotope({
              itemSelector: '.grid-product',
              layoutMode: 'fitRows'
            });

            // Autocomplete for the make selector
            $('#fit-my-wheel-makes').autocomplete({
                lookup : makes,
                minChars : 2,
                autoSelectFirst : true,
                onSearchStart : function(query){
                    clearIsotopeProducts();
                    $('.make-container').removeClass('not-active');
                    $('#wheel-tab-panel .search-result').html('');
                    $('#car-models').html('');
                    $('#car-tires').html('');
                    $('.accordion').foundation('up', $('#model-panel'));
                    $('.accordion').foundation('up', $('#tire-panel'));
                    $(".model-accordion").addClass('disabled');
                    $(".tire-accordion").addClass('disabled');
                },
                onSelect : function(suggestion) {
                    fitMyWheelData.selectCarMake(suggestion.data);
                }
            });
        }

        if($('#rim-panel').length > 0){
            fitMyRimData = new FitMyRims(fullURL);
            $('#rim-panel .accordion-item').addClass('disabled');
            $('#rim-panel .accordion-item:first-child').removeClass('disabled');

            $("#rim-widths").on("click", "span", function() {
                var widthSelected = $(this).attr('data-width');
                $('#rim-tab-panel .search-result').html('');
                $('#rim-heights').html('');
                $('#rim-rims').html('');
                fitMyRimData.selectRimWidth(widthSelected);
                $('#rim-widths span').removeClass('selected');
                $(this).addClass('selected');
            });

            $("#rim-heights").on("click", "span", function() {
                var heightSelected = $(this).attr('data-height');
                fitMyRimData.selectRimHeight(heightSelected);
                $('#rim-heights span').removeClass('selected');
                $(this).addClass('selected');
            });

            $("#rim-rims").on("click", "span", function() {
                var rimSelected = $(this).attr('data-rim');
                fitMyRimData.selectRimRims(rimSelected);
                $('#rim-rims span').removeClass('selected');
                $(this).addClass('selected');
            });

            var $rimGrid = $('#fit-my-rim-results').isotope({
                itemSelector: '.grid-product',
                layoutMode: 'fitRows'
            });

        }
    }
});